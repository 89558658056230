var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.payments,"footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:"header.payment",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"fill-width background-green pa-1"},[_c('div',{staticClass:"d-flex justify-center"},[_vm._v(_vm._s(header.text))]),_c('v-row',{staticClass:"fill-width d-flex justify-center",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","sm":"4"}},[_vm._v(" Monthly Fee ")]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","sm":"4"}},[_vm._v(" Over rate ")]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","sm":"4"}},[_vm._v(" Bond ")])],1)],1)]}},{key:"header.chargeBack",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"fill-width background-red pa-1"},[_c('div',{staticClass:"d-flex justify-center"},[_vm._v(_vm._s(header.text))]),_c('v-row',{staticClass:"fill-width",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","sm":"4"}},[_vm._v(" Monthly Fee ")]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","sm":"4"}},[_vm._v(" Over rate ")]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","sm":"4"}},[_vm._v(" Bond ")])],1)],1)]}},{key:"header.balance",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"fill-width background-orange pa-1"},[_c('div',{staticClass:"d-flex justify-center"},[_vm._v(_vm._s(header.text))]),_c('v-row',{staticClass:"fill-width",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","sm":"3"}},[_vm._v(" Monthly Fee ")]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","sm":"2"}},[_vm._v(" Over rate ")]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","sm":"2"}},[_vm._v(" Bond ")]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","sm":"2"}},[_vm._v(" Amount ")]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","sm":"3"}},[_vm._v(" Range ")])],1)],1)]}},{key:"item.createAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cursor-pointer"},[_vm._v(" "+_vm._s(_vm.formatDate(item.createAt))+" ")])]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cursor-pointer"},[_vm._v(" "+_vm._s(item.createdBy ? item.createdBy.fullname:'System')+" ")])]}},{key:"item.selectedDate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cursor-pointer"},[_vm._v(" "+_vm._s(item.monthByAgent ? item.monthByAgent + ", " + item.yearByAgent : _vm.formatDateDet(item.createAt))+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cursor-pointer",class:item.amount > 0 ? 'green--text' : 'red--text'},[_vm._v(" "+_vm._s(_vm._f("currency")(item.amount))+" ")])]}},{key:"item.payment",fn:function(ref){
var item = ref.item;
return [(item)?[_c('v-row',{staticClass:"fill-width fill-height background-green",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"4"}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.payment.monthlyfee))+" ")]),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"4"}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.payment.over))+" ")]),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"4"}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.payment.bond))+" ")])],1)]:_vm._e()]}},{key:"item.chargeBack",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"fill-width fill-height background-red",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"4"}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.payment.monthlyfee))+" ")]),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"4"}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.payment.over))+" ")]),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"4"}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.payment.bond))+" ")])],1)]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"fill-width fill-height background-orange",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"3"}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.balance.monthlyfee))+" ")]),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"2"}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.balance.over))+" ")]),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"2"}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.balance.bond))+" ")]),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"2"}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.balance.amount))+" ")]),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"3"}},[_vm._v(" "+_vm._s(item.balance.range)+" ")])],1)]}}],null,true)})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }